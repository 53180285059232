<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      class="mt-10"
      v-if="!loading_table"
    ></v-skeleton-loader>

    <v-card v-if="loading_table" class="mt-10" style="margin-bottom: 100px">
      <v-card-title>
        <h4>ارباح الشهادات</h4>
      </v-card-title>
      <v-data-table
        class="text-center"
        :headers="headers"
        :items="tbody"
        :footer-props="$store.state.footer_props"
      >
        <template v-slot:[`item.number`]="{ item }">
          {{item.number}}#
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ Intl.NumberFormat("en-US").format(item.amount.toFixed(1)) }}USDT
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "profit",
  data() {
    return {
      loading_table: false,
      headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ المعاملة",
          align: "center",
        },

        {
          value: "amount",
          text: "المبلغ",
          align: "center",
        },
      ],
      tbody: [],
    };
  },
  methods: {
    get_profits() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "profits",
            method: "get",
          },
        })
        .then((res) => {
          this.loading_table = true;
          this.tbody = res.data.data;
        })
        .catch((err) => {
          // console.log(err.response);
        });
    },
  },
  created() {
    this.get_profits();
  },
};
</script>
